import React, { useState, useMemo } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SimpleSection from "../components/sections/simpleSection/SimpleSection";
import BreadCrumps from "../ui/breadCrumps/BreadCrumps";
import Clubcards from "../components/sections/cards/Clubcards";
import { HelmetDatoCms } from "gatsby-source-datocms";
import LiqpayForm from "../ui/LiqpayForm";
import { Helmet } from "react-helmet";
import axios from "axios";

const Cards = ({ data }) => {
  let fg = [];
  const [shown, setShown] = useState(false);

  const [formData, setFormData] = useState({
    amount: "",
    productname: "",
  });

  const [checked, setChecked] = useState(true);
  const [liqpayData, setLiqPayData] = useState("");
  const [liqpaySrow, setLiqPaySrow] = useState("");
  const [canBuy, setCanBuy] = useState(false);

  const [personIsValid, setPersonIsValid] = useState(false);

  const [filterParametr, setFilterParametr] = useState("");

  const [name, setName] = useState("");
  const [nameWarning, setNameWarning] = useState(false);
  const [sname, setSname] = useState("");
  const [snameWarning, setSNameWarning] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneWarning, setPhoneWarning] = useState(false);
  const [email, setEmail] = useState("");
  const [emailWarning, setEmailWarning] = useState(false);
  const [commit, setCommit] = useState("");

  const handleChange = (e) => {
    if (e.target.name == "name") {
      setName(e.target.value);
      setNameWarning(false);
    }
    if (e.target.name == "sname") {
      setSname(e.target.value);
      setSNameWarning(false);
    }
    if (e.target.name == "phone") {
      setPhone(e.target.value);
      setPhoneWarning(false);
    }
    if (e.target.name == "email") {
      setEmail(e.target.value);
      setEmailWarning(false);
    }
    if (e.target.name == "commit") {
      setCommit(e.target.value);
    }
  };

  //визначення типів
  const termsList = data.termslist.nodes;
  termsList.forEach((element) => {
    fg.push(element.title);
  });
  //фільтрація типів
  const filtersList = ["", ...fg];
  const filterdData = useMemo(() => {
    let filtered = data.cards.edges.filter(({ node: item }) =>
      item.termin.title.includes(filterParametr)
    );
    return filtered;
  }, [filterParametr, data.cards.edges]);

  const checkIsPersomComplete = () => {
    function validateEmail(emailtest) {
      var re = /\S+@\S+\.\S+/;
      return re.test(emailtest);
    }
    let validPersonInputs = {
      isValidname: name.length > 3 ? true : false,
      isValidsname: sname.length > 3 ? true : false,
      isValidphone: phone.length > 10 ? true : false,
      isValidemail: validateEmail(email) ? true : false,
      ischecked: checked,
    };

    if (Object.values(validPersonInputs).includes(false)) {
      if (!validPersonInputs.isValidname) setNameWarning(true);
      if (!validPersonInputs.isValidsname) setSNameWarning(true);
      if (!validPersonInputs.isValidphone) setPhoneWarning(true);
      if (!validPersonInputs.isValidemail) setEmailWarning(true);
      setPersonIsValid(false);
      return;
    } else {
      // запит на кодування
      postData(
        formData.amount,
        formData.productname,
        name,
        sname,
        phone,
        email,
        commit
      );
      //перекл на кнопку оплати
      setPersonIsValid(true);
    }
  };

  const postData = async (
    itemAmount,
    itemProductName,
    userName,
    userSname,
    userPhone,
    userEmail,
    userCommit
  ) => {
    function text(value) {
      let str =
        "Oлімп - клубна карта:  " +
        value +
        "  iмя: " +
        userName +
        "  призвіще: " +
        userSname +
        "  телефон: " +
        userPhone +
        "  email: " +
        userEmail +
        "коментар" +
        userCommit;
      return str;
    }
    if (checked) {
      await axios
        .post(process.env.GATSBY_SERVER_API, {
          amount: itemAmount,
          description: text(itemProductName),
        })
        .then((response) => {
          if (response.data.status) {
            setLiqPayData(response.data.data);
            setLiqPaySrow(response.data.signature);
            setCanBuy(true);
          } else {
            setLiqPayData("");
            setLiqPaySrow("");
            setCanBuy(false);
          }
        })
        .catch((error) => {
          setLiqPayData("");
          setLiqPaySrow("");
          setCanBuy(false);
          console.log(error);
        });
    }
  };

  const clearFormData = () => {
    setFormData({
      amount: "",
      productname: "",
    });
    setName("");
    setSname("");
    setPhone("");
    setEmail("");
    setCommit("");
    setShown(false);
    setPersonIsValid(false);
  };

  const choseItemToBuy = (item) => {
    setShown(true);
    setFormData({
      amount: item.price,
      productname: item.title,
    });
    // postData(item, name, sname, phone, email, commit)
  };

  const hanndlerCheck = () => {
    setChecked(!checked);
  };

  const setFilterBy = (filterParam) => {
    setFilterParametr(filterParam);
  };

  return (
    <>
      <HelmetDatoCms seo={data.page.seo} favicon={data.site.favicon} />
      <Helmet>
        <script src="//static.liqpay.ua/libjs/checkout.js" async></script>
      </Helmet>
      <Layout>
        <BreadCrumps title={data.page.title} />
        <SimpleSection
          id="clubcards"
          sectionTitle={data.page.sectiontitle}
          sectionTitleDesc={data.page.sectiondescription}
          sectiontitleBtnTxt=""
          sectiontitleBtnUrl={false}
          isBlackBg={false}
          customClass="liqpay-cards"
        >
          <div className="warning-container">
            <div
              style={{ padding: `42px 20px`, color: `tomato` }}
              dangerouslySetInnerHTML={{ __html: `${data.page.warning}` }}
            ></div>
          </div>

          <div className="cardtype-filter">
            {filtersList.map((filterBtn, index) => {
              return (
                <button
                  type="button"
                  key={index}
                  onClick={() => setFilterBy(filterBtn)}
                  className={
                    filterParametr === filterBtn
                      ? "filter-btn active"
                      : "filter-btn"
                  }
                >
                  {filterBtn === "" ? "УCI КАРТКИ" : filterBtn}
                </button>
              );
            })}
          </div>
          {!data.page.toogleclubcards ? (
            <>
              {data.cards.edges && (
                <Clubcards
                  data={filterdData}
                  chooseMethod={(item) => choseItemToBuy(item)}
                />
              )}
            </>
          ) : (
            <div>
              <h4>Зачекайте, дані по картам оновлуються..</h4>
              <br />
              <br />
            </div>
          )}
        </SimpleSection>
        {shown ? (
          <LiqpayForm
            close={() => clearFormData()}
            checked={checked}
            formData={formData}
            hanndlerCheck={hanndlerCheck}
            liqpayData={liqpayData}
            liqpaySrow={liqpaySrow}
            canBuy={canBuy}
            personName={name}
            personSname={sname}
            personPhone={phone}
            personEmail={email}
            personCommit={commit}
            nameWarning={nameWarning}
            snameWarning={snameWarning}
            phoneWarning={phoneWarning}
            emailWarning={emailWarning}
            handleChange={handleChange}
            checkIsPersomComplete={checkIsPersomComplete}
            personIsValid={personIsValid}
          />
        ) : null}
      </Layout>
    </>
  );
};

export default Cards;

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    page: datoCmsClubcardspage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      sectiontitle
      sectiondescription
      title
      toogleclubcards
      warning
    }
    termslist: allDatoCmsAbonementcardtermin {
      nodes {
        title
      }
    }
    cards: allDatoCmsAbonementcard {
      edges {
        node {
          title
          carddetails
          price
          termin {
            title
          }
        }
      }
    }
  }
`;
