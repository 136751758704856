import React from "react";

const {metadata} = require('../../config');

const LiqpayForm = ({
  formData,
  close,
  checked,
  hanndlerCheck,
  liqpayData,
  liqpaySrow,
  canBuy,
  handleChange,

  personName,
  personSname,
  personPhone,
  personEmail,
  personCommit,

  nameWarning,
  snameWarning,
  phoneWarning,
  emailWarning,

  personIsValid,

  checkIsPersomComplete,
}) => {

  return (
    <div className="backdrop" onClick={() => close()} role="presentation">
      <div className="form__wrapper">
        <div onClick={(event) => event.stopPropagation()} className="form" role="presentation">
          <div className="form--head">
            <button
              type="button"
              aria-label="Close button"
              className="close--btn"
              onClick={() => close()}
            ></button>
          </div>
          <legend className="mb__16">
            <span className="ol__green">Спосіб оплати: </span>
            Оплата картою Visa/MasterCard (LiqPay)::Payment via Visa /
            MasterCard (LiqPay)
          </legend>
          <div className="form--row">
            <h3>
              {" "}
              <span className="ol__green">Клубна карта: </span>{" "}
              {formData.productname}
            </h3>
          </div>
          {/* <span className='ol__green'>
          Вартість:
          </span>
          <div className='form--row'>
            <h3>{formData.amount} грн.</h3>
          </div> */}

          {!personIsValid && (
            <React.Fragment>
              <h5>Платіжні дані</h5>

              <div className="form--row">
                <label htmlFor="name">Ім’я *</label>
                <input
                  type="text"
                  placeholder="Iмя"
                  id="name"
                  value={personName}
                  onChange={handleChange}
                  name="name"
                  maxLength={20}
                />
                {nameWarning && (
                  <p className="form__warning">
                    Поле є обов'язковим для заповнення
                  </p>
                )}
              </div>
              <div className="form--row">
                <label htmlFor="sname">Прізвище *</label>
                <input
                  type="text"
                  placeholder="Прізвище"
                  id="sname"
                  value={personSname}
                  onChange={handleChange}
                  name="sname"
                  maxLength={30}
                />

                {snameWarning && (
                  <p className="form__warning">
                    Поле є обов'язковим для заповнення
                  </p>
                )}
              </div>
              <div className="form--row">
                <label htmlFor="phone">Телефон *</label>
                <input
                  type="text"
                  placeholder="Контактний номер"
                  value={personPhone}
                  onChange={handleChange}
                  name="phone"
                  id="phone"
                  maxLength={30}
                />

                {phoneWarning && (
                  <p className="form__warning">
                    Поле є обов'язковим для заповнення
                  </p>
                )}
              </div>
              <div className="form--row">
                <label htmlFor="email"> Адреса електронної пошти *</label>
                <input
                  type="email"
                  placeholder="Ваш мейл"
                  value={personEmail}
                  onChange={handleChange}
                  name="email"
                />
                {emailWarning && (
                  <p className="form__warning">Введіть коректний імейл</p>
                )}
              </div>
              <div className="form--row mb__16">
                <label htmlFor="commit">Примітки до замовлень *</label>
                <textarea
                  rows="4"
                  cols="50"
                  maxLength={160}
                  value={personCommit}
                  placeholder="Нотатки до вашого замовлення, наприклад спеціальні примітки для доставки."
                  name="commit"
                  id="commit"
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="form--row oferta">
                <label htmlFor="oferta">
                  <input
                    type="checkbox"
                    checked={checked}
                    id="oferta"
                    name="oferta"
                    style={{
                      marginRight: `6px`,
                      width: `20px`,
                      height: `20px`,
                    }}
                    onChange={hanndlerCheck}
                  />
                  <span>
                  Я погоджуюсь з {" "}<a
                    href={`${metadata.siteUrl}/oferta`}
                    className="oferta--link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    правилами даної оферти
                    </a>
                  </span>
                </label>
              </div>
              <h5  style={{textAlign: 'center', paddingBottom: '12px'}}>
              «Абонемент потрібно активувати та забрати на рецепції будь-якого клубу протягом місяця з моменту оплати»
              </h5>
              <div className="centered">
                <button onClick={checkIsPersomComplete} className="ol__button">
                  Перейти до оплати
                </button>
              </div>
            </React.Fragment>
          )}
          {personIsValid && (
            <React.Fragment>
              {canBuy && (
                <div
                  className="centered"
                  style={{ padding: `40px 0px 50px 0` }}
                >
                  <form
                    method="POST"
                    action="https://www.liqpay.ua/api/3/checkout"
                    acceptCharset="utf-8"
                  >
                    <input type="hidden" name="data" value={liqpayData} />
                    <input type="hidden" name="signature" value={liqpaySrow} />
                    <button className="liqpay__custom-btn">
                      <img
                        src="https://static.liqpay.ua/buttons/logo-small.png"
                        name="btn_text"
                        alt="liqpay"
                        style={{
                          marginRight: `7px !important`,
                          verticalAlign: `middle !important`,
                        }}
                      />
                      <span style={{ verticalAlign: `middle !important` }}>
                        Оплатити {formData.amount} UAH
                      </span>
                    </button>
                  </form>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default LiqpayForm;
