module.exports = {
  metadata: {
      title: "Olimp strong",
      description: ``,
      author: `@rinzlertes`,
      siteUrl: `https://olimp-strong.com.ua`,
      phone: `0974105050`,
      youtubechanel: `https://www.youtube.com/@olimplviv6571`
  },
};
  