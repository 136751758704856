import React from 'react'
const clubcards = ({data , chooseMethod}) => {
    const getItem =(card)=> {
        let price = card.price;
        let title = card.title;
        let obj = {
            price, title
        }
        chooseMethod(obj)
    }
    const isBlackBg = false;
    return (
            <div className='abonenement__cards'>
                    {
                        data.map(({node : card}, index)=>{
                            return (
                                <div key={index} className='abonenement__card'>
                                    <div className='card__wrapper'>
                                        <h3>{card.title}</h3>
                                        <div dangerouslySetInnerHTML={{__html:  `${card.carddetails}`}}></div>
                                        <br/>
                                        <p>
                                            вартість : <strong>{card.price}</strong> грн.
                                        </p>
                                        <br/>
                                        <div>
                                            <button onClick={()=>getItem(card)} type="button" className={isBlackBg? "ol__btn ol__btn_white": "ol__btn ol__btn_black"}>
                                                ПРИДБАТИ
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
            </div>
    )
}

export default clubcards
